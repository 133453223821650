<template>
  <el-row :gutter="20">
    <el-col 
      :sm="12"
      class="sticky"
    >
      <SlidingWall />
    </el-col>
    <el-col :sm="12">
      <el-form class="form">
        <el-collapse
          v-model="activeStep"
          accordion
        >
          <el-collapse-item
            :name="componentList.Format"
          >
            <template slot="title">
              <h3>{{ $t('dimensision') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Format"
                class="status-icon"
                icon="check"
              />
            </template>
            
            <Format
              :min-length-multiplier="2"
              :max-length-multiplier="10"
              :show-weight="false"
              :possible-formats="[]"
              @nextStep="nextStep($event)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.OpeningDirection"
            :disabled="activeStep < componentList.OpeningDirection"
          >
            <template slot="title">
              <h3>{{ $t('wings') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.OpeningDirection"
                class="status-icon"
                icon="check"
              />
            </template>

            <OpeningDirection />
            <NumberOfLanes />
            <div align="right">
              <el-button
                type="primary"
                @click="nextStep(componentList.OpeningDirection + 1)"
              >
                {{ $t('nextBtn') }}
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Color"
            :disabled="activeStep < componentList.Color"
          >
            <template slot="title">
              <h3>{{ $t('painting') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Color"
                class="status-icon"
                icon="check"
              />
            </template>
            <Lackierung
              label="Weitere Farben"
              :show-surfaces="true"
              color-catalog="ral"
              @nextStep="nextStep(componentList.Color + 1)"
            />
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Handling"
            :disabled="activeStep < componentList.Handling"
          >
            <template slot="title">
              <h3>{{ $t('handling') }}</h3>
              <font-awesome-icon
                v-if="activeStep > componentList.Handling"
                class="status-icon"
                icon="check"
              />
            </template>
            <Handling />
            <div align="right">
              <el-button
                type="primary"
                @click="nextStep(componentList.Handling + 1)"
              >
                {{ $t('nextBtn') }}
              </el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item
            :name="componentList.Extras"
            :disabled="activeStep < componentList.Extras"
          >
            <template slot="title">
              <h3>{{ $t('extras') }}</h3>
            </template>
            <Extras />
            <div align="right">
              <el-button
                type="primary"
                data-iframe-height
                :loading="loading"
                @click="checkout"
              >
                {{ chkoutBtnText }}
              </el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import SlidingWall from "@/components/Layout/SlidingWall/Index.vue";
import Format from '@/components/Layout/Format.vue';
import OpeningDirection from '@/components/SlidingWall/OpeningDirection.vue';
import NumberOfLanes from '@/components/SlidingWall/NumberOfLanes.vue';
import Lackierung from "@/components/Gestaltung/Folienfarbe.vue";
import Handling from "../components/SlidingWall/Handling.vue";
import Extras from "../components/Extras.vue";
import i18n from "@/js/plugins/i18n";

export default {
  name: "SchiebewandKonfigurator",
  components: {
    SlidingWall,
    Format,
    OpeningDirection,
    NumberOfLanes,
    Lackierung,
    Handling,
    Extras
  },
  props: {
    whatIf: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      componentList: {
        Format: 1,
        OpeningDirection: 2,
        Color: 3,
        Handling: 4,
        Extras: 5
      },
      chkoutBtnText: this.$t('chkoutDialogBtn'),
      loading: false,
      minLanes: 2,
      maxLanes: 5,
      bothDirections: "left_right"
    };
  },
  computed: {
    ...mapGetters([
      "getMaxWidth",
      "getPanelMultiplier"
    ]),
    ...mapFields([
      "activeStep",
      "configId",
      "fitting",
      "lanes",
      "length",
      "minWidth",
      "height",
      "selectedEdge"
    ]),
    maxWidth() {
      return this.getMaxWidth * this.lanes * this.getPanelMultiplier;
    },
    getMinWidth() {
      return this.minWidth * this.lanes * this.getPanelMultiplier;
    }
  },
  watch: {
    activeStep(newVal, oldVal) {
      if (!newVal && oldVal > 1) {
        this.activeStep = oldVal - 1;
      }
      this.$emit("datachange");
    },
    length() {
      this.calculatePanel();
    },
    height() {
      this.calculatePanel();
    }
  },
  created() {
    this.lanes = this.minLanes;
    this.selectedEdge = i18n.t('edgeHighGlossTitle');
    this.calculatePanel();
    this.$emit("loadSavedConfig");
  },
  methods: {
    calculatePanel() {
      if (
        this.length > this.maxWidth &&
        (this.lanes < this.maxLanes || this.fitting !== this.bothDirections)
      ) {
        this.increase();
      } else if(
        this.length < this.getMinWidth && 
        (this.lanes > this.minLanes || this.fitting === this.bothDirections)
      ) {
        this.decrease();
      }
    },
    increase() {
      while(this.length > this.maxWidth && this.lanes < this.maxLanes) {
        this.lanes++;
      }
      if (this.length > this.maxWidth && this.fitting !== this.bothDirections) {
        this.fitting = this.bothDirections;
        this.lanes = this.minLanes;
        this.increase();
      }
    },
    decrease() {
      while(this.length < this.getMinWidth && this.lanes > this.minLanes) {
        this.lanes--;
      }
      if (this.length < this.getMinWidth && this.fitting === this.bothDirections) {
        this.fitting = "left";
      }
    },
    nextStep() {
      this.activeStep = Object.values(this.componentList)[this.activeStep];
    },
    checkout() {
      this.loading = true;
      this.chkoutBtnText = this.$t('chkoutDialogBtnLoading');
      const items = this.$store.getters.getSlidingWallLineItems;

      if (this.whatIf) {
        this.$emit("postLineItems", null, items);
        this.chkoutBtnText = this.$t('chkoutDialogBtn'),
        this.loading = false;
      } else {
        this.$http
          .post(
            process.env.VUE_APP_CHECKOUT,
            this.$store.getters.getSlidingWallProduct
          )
          .then(response => {
            this.$emit("postLineItems", response.data, items);
          }).catch(() => {
            this.$emit('notify', {
              title: this.$t('cartError'),
              message: this.$t('addToCartError'),
              type: 'error'
            });
          }).finally(() => {
            this.chkoutBtnText = this.$t('chkoutDialogBtn'),
            this.loading = false;
          });
      }
    }
  }
}
</script>
