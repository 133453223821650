<template>
  <div>
    <el-checkbox-group
      v-model="selection"
      fill="#1bafd6"
      style="margin-bottom: 8px"
      @change="onChange"
    >
      <el-checkbox-button
        v-for="item in data"
        :key="item.id"
        :label="item.value"
        class="card"
      >
        <svg 
          class="check-mark"
          width="80"
          height="80"
          viewBox="0 0 250 250"
        >
          <path
            d="m0 0h250v250h-250z"
            fill-rule="evenodd"
            stroke="none"
          />
          <path
            d="m46.196 74.921 22.882 22.882 47.225-47.225"
            fill="none"
            stroke-linecap="round"
            stroke-width="7.8125"
          />
        </svg>
        <div class="img-box">
          <img
            :src="getImgUrl(item.img)"
            class="image"
          >
        </div>
        <div class="caption">
          <span class="title">{{ item.name }}</span>
          <div class="bottom clearfix">
            <span class="info">{{ $t('select') }}</span>
          </div>
        </div>
        <div class="overlay" />
      </el-checkbox-button>
    </el-checkbox-group>
    <div align="right">
      <el-button
        type="primary"
        @click="next()"
      >
        {{ $t('nextBtn') }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxGroup",
  props: {
    defaultValue: {
      type: Object,
      default: () => (null),
    },
    data: {
      type: Array,
      default: () => ([]),
      validator(values) {
        if (values.length < 1) return false;
        return values.every((value) => {
          return value.hasOwnProperty("id") && value.hasOwnProperty("name") && value.hasOwnProperty("value");
        });
      },
    }
  },
  data() {
    return {
      selection: [],
    };
  },
  methods: {
    getImgUrl(filename) {
      var images = require.context("../assets/", false, /\.jpg$/);
      return images("./" + filename + ".jpg");
    },
    next() {
      this.$emit("nextStep");
    },
    onChange(value) {
      let selected = this.defaultValue;
      if (value.length > 0) {
        selected = value.at(-1);
        this.selection = [selected];
      } else {
        this.selection = [];
      }

      this.$emit("update:selection", selected);
    },
  },
};
</script>