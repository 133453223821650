<template>
  <!-- Shower -->
  <image-component
    :x="x"
    :y="y"
    :file="getFilePath()"
    :flip="flip"
    align="center"
    :scale="scale"
  />
</template>
<script>
import ImageComponent from './ImageComponent.vue';

export default {
  name: "Bathtub",
  components: { ImageComponent },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    flip: {
      type: Boolean,
      default: false
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getFilePath() {
      return `./bathtub.svg`;
    }
  }
}
</script>