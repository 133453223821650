<template>
  <v-line
    :config="{
      x: x,
      y: y,
      points: getPosition(),
      closed: true,
      fillLinearGradientStartPoint: {
        x: 0,
        y: 0
      },
      fillLinearGradientEndPoint: {
        x: thickness,
        y: length
      },
      fillLinearGradientColorStops: getColor(),
      strokeWidth: 0,
      opacity: 1,
      scaleX: scale * (flip ? 1 : -1),
      scaleY: scale
    }"
  />
</template>
<script>
export default {
  name: "StabilizationBar",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    angle: {
      type: Number,
      default: 90,
      validator(value) {
        const acceptedValues = [90, 45];
        return acceptedValues.includes(value);
      }
    },
    flip: {
      type: Boolean,
      default: false
    },
    material: {
      type: String,
      default: 'chrome',
      validator(value) {
        const acceptedValues = ['schwarz', 'chrome', 'edelstahl', 'edelstahl design', 'glanzverchromt'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      thickness: 15,
      length: 150
    }
  },
  methods: {
    getColor() {
      switch(this.material.toLowerCase()) {
        case 'chrome':
          case 'glasnzverchromt':
            return [
              0, "rgb(190, 190, 190)",
              0.4, "rgb(210, 210, 210)",
            0.56, "rgb(225, 225, 225)",
            1, "rgb(200, 200, 200)"
          ];
        case 'edelstahl':
        case 'edelstahl design':
          return [
            0, "rgb(125, 125, 125)",
            0.45, "rgb(204, 204, 204)",
            0.53, "rgb(220, 220, 220)",
            1, "rgb(104, 104, 104)"
          ];
        case 'schwarz':
          return [
            0, 'rgb(0, 0, 0)',
            0.40, "rgb(51, 51, 51)",
            0.6, "rgb(51, 51, 51)",
            1, 'rgb(0, 0, 0)'
          ];
      }
    },
    getPosition() {
      let length = this.length;
      if (this.angle === 45) {
        length = length / 2;
      }

      return [
        0, 0,
        this.thickness, 0,
        this.length - this.thickness, length - this.thickness,
        this.length - this.thickness, length,
        this.length - this.thickness - this.thickness, length,
        0, this.thickness
      ];
    }
  }
}
</script>