var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-rect',{attrs:{"config":{
    x: _vm.x,
    y: _vm.y,
    width: _vm.width,
    height: _vm.height,
    fillLinearGradientStartPoint: {
      x: 0,
      y: 0
    },
    fillLinearGradientEndPoint: {
      x: 0,
      y: _vm.height
    },
    fillLinearGradientColorStops: _vm.getColor(),
    cornerRadius: [10, 10, 5, 5],
    opacity: 1,
    scaleX: _vm.scale * (_vm.flip ? 1 : -1),
    scaleY: _vm.scale
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }