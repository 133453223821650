var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',[_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        0, 0,
        0, _vm.height,
        _vm.thickness, _vm.height,
        _vm.thickness, 0
      ],
      closed: true,
      fillLinearGradientStartPoint: {
        x: 0,
        y: 0
      },
      fillLinearGradientEndPoint: {
        x: _vm.thickness,
        y: 0
      },
      fillLinearGradientColorStops: _vm.getColor(),
      strokeWidth: 0,
    }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }