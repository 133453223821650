<template>
  <!-- Shower -->
  <image-component
    :x="x"
    :y="y"
    :file="getFilePath()"
    :flip="flip"
    align="center"
    :scale="scale"
  />
</template>
<script>
import ImageComponent from './ImageComponent.vue';

export default {
  name: "ShowerSystem",
  components: { ImageComponent },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    flip: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: './',
    },
    material: {
      type: String,
      default: 'chrome',
      validator(value) {
        const acceptedValues = ['schwarz', 'chrome', 'edelstahl', 'edelstahl design', 'glanzverchromt'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    scale: {
      type: Number,
      default: 1
    }
  },
  methods: {
    getFilePath() {
      return `${this.path}${this.material.toLowerCase()}.svg`;
    }
  }
}
</script>