<template>
  <v-rect
    :config="{
      x: x,
      y: y,
      width,
      height,
      fillLinearGradientStartPoint: {
        x: 0,
        y: 0
      },
      fillLinearGradientEndPoint: {
        x: 0,
        y: height
      },
      fillLinearGradientColorStops: getColor(),
      cornerRadius: [10, 10, 5, 5],
      opacity: 1,
      scaleX: scale * (flip ? 1 : -1),
      scaleY: scale
    }"
  />
</template>
<script>
export default {
  name: "StabilizationClip",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    flip: {
      type: Boolean,
      default: false
    },
    material: {
      type: String,
      default: 'chrome',
      validator(value) {
        const acceptedValues = ['schwarz', 'chrome', 'edelstahl', 'edelstahl design', 'glanzverchromt'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    scale: {
      type: Number,
      default: 1
    } 
  },
  data() {
    return {
      width: 20,
      height: 40
    }
  },
  methods: {
    getColor() {
      switch(this.material.toLowerCase()) {
        case 'chrome':
        case 'glasnzverchromt':
          return [
            0, "rgb(200, 200, 200)",
            0.3, "rgb(171, 171, 171)",
            0.4, "rgb(171, 171, 171)",
            0.8, "rgb(255, 255, 255)",
            0.9, "rgb(30, 30, 30)",
            1, "rgb(255, 255, 255)"
          ];
        case 'edelstahl':
        case 'edelstahl design':
          return [
            0, "rgb(125, 125, 125)",
            0.2, "rgb(204, 204, 204)",
            0.4, "rgb(125, 125, 125)",
            0.6, "rgb(220, 220, 220)",
            0.95, "rgb(104, 104, 104)"
          ];
        case 'schwarz':
          return [
            0, 'rgb(0, 0, 0)',
            0.40, "rgb(51, 51, 51)",
            0.6, "rgb(51, 51, 51)",
            1, 'rgb(0, 0, 0)'
          ];
      }
    },
  }
}
</script>