var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',[(_vm.pos === 'back')?_c('v-rect',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      width: _vm.length,
      height: _vm.height,
      fill: _vm.fill,
      shadowColor: 'rgba(0, 0, 0, .1)',
      shadowBlur: 5,
      shadowOffset: { x: 0, y: -5 },
    }}}):_vm._e(),(_vm.pos === 'left' || _vm.pos === 'right')?_c('v-line',{attrs:{"config":{
      x: _vm.x,
      y: _vm.y,
      points: [
        0, 0,
        _vm.thickness, 0,
        _vm.thickness, _vm.height,
        0, _vm.height,
        0, 0,
        _vm.dimensision, Math.abs(_vm.dimensision),
        _vm.dimensision, _vm.height - Math.abs(_vm.dimensision),
        0, _vm.height,
        0, 0
      ],
      closed: true,
      fill: _vm.fill,
      stroke: 'rgba(0, 0, 0, .2)',
      strokeWidth: 1,
    }}}):_vm._e(),(_vm.pos === 'side')?_c('Shadow',{attrs:{"x":_vm.x,"y":_vm.y,"height":_vm.height,"dimensision":_vm.dimensision}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }