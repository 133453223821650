var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-group',[_c('Floor',{attrs:{"x":_vm.x,"y":_vm.y + (_vm.height * _vm.ratio),"length":(_vm.length + _vm.wall.thickness) * _vm.ratio,"dimensision":(_vm.wall.thickness + _vm.wall.dimensision) * _vm.ratio,"thickness":_vm.floor.thickness * _vm.ratio}}),_c('Wall',{attrs:{"x":_vm.x + ((_vm.wall.thickness + _vm.wall.dimensision) * _vm.ratio),"y":_vm.y + (_vm.wall.dimensision * _vm.ratio),"length":(_vm.length - (_vm.wall.dimensision * 2)) * _vm.ratio,"height":(_vm.height - (_vm.wall.dimensision * 2)) * _vm.ratio,"dimensision":_vm.wall.dimensision * _vm.ratio}}),_c('Wall',{attrs:{"pos":_vm.pos,"x":_vm.x + (((_vm.pos === 'left' ? 0 : _vm.length) + _vm.wall.thickness) * _vm.ratio),"y":_vm.y,"height":_vm.height * _vm.ratio,"dimensision":(_vm.pos === 'left' ? 1 : -1) * _vm.wall.dimensision * _vm.ratio,"thickness":(_vm.pos === 'left' ? -1 : 1) * _vm.wall.thickness * _vm.ratio}}),_c('Drain',{attrs:{"x":_vm.x + (((_vm.flip ? _vm.length - _vm.offset : _vm.offset) + _vm.wall.thickness) * _vm.ratio),"y":_vm.y + ((_vm.height - (_vm.wall.dimensision / 2)) * _vm.ratio),"length":75 * _vm.ratio,"height":50 * _vm.ratio}}),(_vm.length && _vm.inputLength >= 600 && _vm.height && _vm.height >= 1400)?_c('ShowerSystem',{attrs:{"x":_vm.x + ((_vm.flip ? _vm.length - _vm.wall.dimensision - 120 : 155 + _vm.wall.dimensision + _vm.wall.thickness) * _vm.ratio),"y":_vm.y + _vm.getShowerPosY(),"length":_vm.length,"height":_vm.height,"flip":_vm.flip,"scale":_vm.ratio,"path":"./walkin/","material":_vm.farbe}}):_vm._e(),_c('v-group',[_c('v-line',{attrs:{"config":{
        x: _vm.x - Math.min(250 * _vm.ratio, 35),
        y: _vm.y,
        points: [5, 0, 0, 0, 0, _vm.height * _vm.ratio, 5, _vm.height * _vm.ratio],
        stroke: 'black',
        strokeWidth: 1,
        strokeScaleEnabled: false
      }}}),_c('v-label',{attrs:{"config":{
        x: _vm.x - Math.min(250 * _vm.ratio, 35) - 5,
        y: 20 + (_vm.height * _vm.ratio / 2) + 25
      }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff', rotation: 270 }}}),_c('v-text',{attrs:{"config":{
          text: `${_vm.inputHeight} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          rotation: 270,
          width: 50
        }}})],1),_c('v-line',{attrs:{"config":{
        x: _vm.x + (((_vm.flip ? _vm.offset : 0) + _vm.wall.thickness) * _vm.ratio),
        y: (_vm.height * _vm.ratio) + _vm.y + Math.min(300 * _vm.ratio, 35),
        points: [ _vm.inputLength * _vm.ratio, -5, _vm.inputLength * _vm.ratio, 0, 0, 0, 0, -5 ],
        stroke: 'black',
        strokeWidth: 1,
        strokeScaleEnabled: false
      }}}),_c('v-label',{attrs:{"config":{
        x: _vm.x + ((_vm.inputLength + (_vm.flip ? _vm.offset * 2 : 0) + _vm.wall.thickness + _vm.wall.thickness) * _vm.ratio) / 2 - 25,
        y: _vm.height * _vm.ratio + _vm.y + Math.min(300 * _vm.ratio, 35) - 5
      }}},[_c('v-tag',{attrs:{"config":{ fill: '#fff' }}}),_c('v-text',{attrs:{"config":{
          text: `${_vm.inputLength} mm`,
          fontSize: 10,
          align: 'center',
          verticalAlign: 'middle',
          width: 50
        }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }