<template>
  <!--
    S T A R T   C A B I N
    -------------------------------------------------------------------------
  -->
  <v-group>
    <Floor 
      :x="x"
      :y="y + (height * ratio)"
      :length="(length + wall.thickness) * ratio"
      :dimensision="(wall.thickness + wall.dimensision) * ratio"
      :thickness="floor.thickness * ratio"
    />

    <!-- Back wall -->
    <Wall
      :x="x + ((wall.thickness + wall.dimensision) * ratio)"
      :y="y + (wall.dimensision * ratio)"
      :length="(length - (wall.dimensision * 2)) * ratio"
      :height="(height - (wall.dimensision * 2)) * ratio"
      :dimensision="wall.dimensision * ratio"
    />
    
    <!-- wall -->
    <Wall
      :pos="pos"
      :x="x + (((pos === 'left' ? 0 : length) + wall.thickness) * ratio)"
      :y="y"
      :height="height * ratio"
      :dimensision="(pos === 'left' ? 1 : -1) * wall.dimensision * ratio"
      :thickness="(pos === 'left' ? -1 : 1) * wall.thickness * ratio"
    />

    <Drain
      :x="x + (((flip ? length - offset : offset) + wall.thickness) * ratio)"
      :y="y + ((height - (wall.dimensision / 2)) * ratio)"
      :length="75 * ratio"
      :height="50 * ratio"
    />

    <ShowerSystem
      v-if="length && inputLength >= 600 && height && height >= 1400"
      :x="x + ((flip ? length - wall.dimensision - 120 : 155 + wall.dimensision + wall.thickness) * ratio)"
      :y="y + getShowerPosY()"
      :length="length"
      :height="height"
      :flip="flip"
      :scale="ratio"
      path="./walkin/"
      :material="farbe"
    />

    <v-group>
      <v-line
        :config="{
          x: x - Math.min(250 * ratio, 35),
          y: y,
          points: [5, 0, 0, 0, 0, height * ratio, 5, height * ratio],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x - Math.min(250 * ratio, 35) - 5,
          y: 20 + (height * ratio / 2) + 25
        }"
      >
        <v-tag :config="{ fill: '#fff', rotation: 270 }" />
        <v-text
          :config="{
            text: `${inputHeight} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            rotation: 270,
            width: 50
          }"
        />
      </v-label>

      <v-line
        :config="{
          x: x + (((flip ? offset : 0) + wall.thickness) * ratio),
          y: (height * ratio) + y + Math.min(300 * ratio, 35),
          points: [ inputLength * ratio, -5, inputLength * ratio, 0, 0, 0, 0, -5 ],
          stroke: 'black',
          strokeWidth: 1,
          strokeScaleEnabled: false
        }"
      />
      <v-label
        :config="{
          x: x + ((inputLength + (flip ? offset * 2 : 0) + wall.thickness + wall.thickness) * ratio) / 2 - 25,
          y: height * ratio + y + Math.min(300 * ratio, 35) - 5
        }"
      >
        <v-tag :config="{ fill: '#fff' }" />
        <v-text
          :config="{
            text: `${inputLength} mm`,
            fontSize: 10,
            align: 'center',
            verticalAlign: 'middle',
            width: 50
          }"
        />
      </v-label>
    </v-group>
  </v-group>
  <!--
    -------------------------------------------------------------------------
    C A B I N   E N D
  -->
</template>
<script>
import Floor from "../Parts/Floor.vue";
import Wall from "../Parts/Wall.vue";
import Drain from '../Parts/Drain.vue';
import ShowerSystem from '../Parts/ShowerSystem.vue';

export default {
  name: "Cabin",
  components: {
    Floor,
    Wall,
    Drain,
    ShowerSystem
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    flip: {
      type: Boolean,
      default: false
    },
    offset: {
      type: Number,
      default: 0
    },
    pos: {
      type: String,
      default: 'left',
      validator(value) {
        const acceptedValues = ['left', 'right'];
        return acceptedValues.includes(value.toLowerCase());
      }
    },
    length: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    inputLength: {
      type: Number,
      default: 0
    },
    inputHeight: {
      type: Number,
      default: 0
    },
    wall: {
      type: Object,
      default: () => ({
        thickness: 40,
        dimensision: 100
      })
    },
    ratio: {
      type: Number,
      default: 1
    },
    farbe: {
      type: String,
      default: "chrome"
    }
  },
  data() {
    return {
      floor: {
        thickness: 15,
      },
    };
  },
  methods: {
    getShowerPosY(){
      let posY = (this.height - 2000) * this.ratio;
      if (posY < 0) {
        posY = -this.y
      }
      return posY;
    }
  }
}
</script>
