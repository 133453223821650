<template>
  <v-group>
    <v-line
      :config="{
        x: x,
        y: y,
        points: [
          0, 0,
          0, height,
          thickness, height,
          thickness, 0
        ],
        closed: true,
        fillLinearGradientStartPoint: {
          x: 0,
          y: 0
        },
        fillLinearGradientEndPoint: {
          x: thickness,
          y: 0
        },
        fillLinearGradientColorStops: getColor(),
        strokeWidth: 0,
      }"
    />
  </v-group>
</template>
<script>
export default {
  name: "Rail",
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    material: {
      type: String,
      default: 'chrome',
      validator(value) {
        const acceptedValues = ['schwarz', 'chrome', 'edelstahl', 'edelstahl design', 'glanzverchromt'];
        return acceptedValues.includes(value.toLowerCase());
      },
    },
    thickness: {
      type: Number,
      default: 7
    }
  },
  methods: {
    getColor() {
      switch(this.material.toLowerCase()) {
        case 'chrome':
        case 'glasnzverchromt':
        return [
            0, "rgb(190, 190, 190)",
            0.4, "rgb(210, 210, 210)",
            0.56, "rgb(225, 225, 225)",
            1, "rgb(200, 200, 200)"
          ];
        case 'edelstahl':
        case 'edelstahl design':
          return [
            0, "rgb(125, 125, 125)",
            0.45, "rgb(204, 204, 204)",
            0.53, "rgb(220, 220, 220)",
            1, "rgb(104, 104, 104)"
          ];
        case 'schwarz':
          return [
            0, 'rgb(0, 0, 0)',
            0.40, "rgb(51, 51, 51)",
            0.6, "rgb(51, 51, 51)",
            1, 'rgb(0, 0, 0)'
          ];
      }
    },
  }
}
</script>