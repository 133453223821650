var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-line',{attrs:{"config":{
    x: _vm.x,
    y: _vm.y,
    points: _vm.getPosition(),
    closed: true,
    fillLinearGradientStartPoint: {
      x: 0,
      y: 0
    },
    fillLinearGradientEndPoint: {
      x: _vm.thickness,
      y: _vm.length
    },
    fillLinearGradientColorStops: _vm.getColor(),
    strokeWidth: 0,
    opacity: 1,
    scaleX: _vm.scale * (_vm.flip ? 1 : -1),
    scaleY: _vm.scale
  }}})
}
var staticRenderFns = []

export { render, staticRenderFns }