var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-stage',{ref:"stage",attrs:{"config":_vm.stageSize}},[(_vm.showStage())?_c('v-layer',[(_vm.showStage())?_c('Cabin',{attrs:{"x":_vm.x + _vm.offset.x,"y":_vm.y + _vm.offset.y,"length":_vm.length + _vm.walkInOffset,"height":_vm.height,"offset":_vm.walkInOffset,"wall":_vm.wall,"flip":_vm.fitting == 'right',"pos":_vm.fitting,"ratio":_vm.ratio,"farbe":_vm.farbe,"input-length":_vm.length,"input-height":_vm.height}}):_vm._e(),(_vm.selectedStabilization.length === 1)?_c('StabilizationBar',{attrs:{"x":parseInt(_vm.selectedStabilization[0].connection) !== 45 ?
            _vm.x + _vm.offset.x + ((_vm.wall.thickness + (_vm.fitting == 'left' ? _vm.realLength - 150 : _vm.walkInOffset + 150)) * _vm.ratio) :
            _vm.x + _vm.offset.x + ((_vm.wall.thickness + (_vm.fitting == 'left' ? (_vm.wall.dimensision / 2) + 125 : _vm.length + _vm.walkInOffset - (_vm.wall.dimensision / 2) - 125)) * _vm.ratio),"y":_vm.y + _vm.offset.y,"angle":parseInt(_vm.selectedStabilization[0].connection) || 90,"flip":_vm.fitting == 'right',"material":_vm.farbe,"scale":_vm.ratio}}):_vm._e(),_c('Glass',{attrs:{"x":_vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset + (_vm.fitting == 'right' ? _vm.walkInOffset : 0)) * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.realLength * _vm.ratio,"height":_vm.realHeight * _vm.ratio,"input-length":_vm.realLength,"input-height":_vm.realHeight,"active":!_vm.activeInput.startsWith('hinge_'),"color":_vm.glassColor,"lines":_vm.glassDesign,"line-x":_vm.x + _vm.offset.x + ((_vm.wall.thickness + _vm.hinge.offset) * _vm.ratio),"scale":_vm.ratio}}),(_vm.selectedStabilization.length === 1)?_c('StabilizationClip',{attrs:{"x":parseInt(_vm.selectedStabilization[0].connection) !== 45 ?
            _vm.x + _vm.offset.x + ((_vm.wall.thickness + (_vm.fitting == 'left' ? _vm.realLength - 151 : _vm.walkInOffset + 151)) * _vm.ratio) :
            _vm.x + _vm.offset.x + ((_vm.wall.thickness + (_vm.fitting == 'left' ? (_vm.wall.dimensision / 2) + 124 : _vm.length + _vm.walkInOffset - (_vm.wall.dimensision / 2) - 124)) * _vm.ratio),"y":_vm.y + _vm.offset.y - (8 * _vm.ratio),"flip":_vm.fitting == 'right',"material":_vm.farbe,"scale":_vm.ratio}}):_vm._e(),(_vm.selectedHinge && /Grundprofil|Klemmschiene/i.test(_vm.selectedHinge.type))?_c('v-group',[_c('Rail',{attrs:{"x":_vm.x + _vm.offset.x + ((_vm.fitting == 'left' ? _vm.wall.thickness : _vm.length + _vm.walkInOffset) * _vm.ratio),"y":_vm.y + _vm.offset.y,"length":_vm.hinge.offset * _vm.ratio,"height":_vm.height * _vm.ratio,"material":_vm.farbe,"thickness":45 * _vm.ratio}})],1):_c('v-group',_vm._l((_vm.hingePositions),function(h,index){return _c('Hinge',{key:`hinge_${index}`,attrs:{"x":_vm.fitting == 'left' ? 
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) :
            _vm.x + _vm.offset.x + (_vm.wall.thickness * _vm.ratio) + ((_vm.realLength + _vm.walkInOffset) * _vm.ratio),"y":_vm.y + _vm.offset.y + (h.y * _vm.ratio),"label":h.y,"offset-y":_vm.offset.y,"active":_vm.activeInput == `hinge_${index}`,"flip":_vm.fitting !== 'left',"scale":_vm.ratio,"form":_vm.hinge.form,"material":_vm.farbe,"type":'angle',"connection":'wall'}})}),1)],1):_vm._e()],1),_c('el-alert',{staticStyle:{"margin-top":"12px"},attrs:{"type":"info","center":"","show-icon":"","closable":false}},[_vm._v(" "+_vm._s(_vm.$t('glassDoorDimensision'))+" "+_vm._s(_vm.realLength)+" x "+_vm._s(_vm.realHeight)+" mm ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }